
import { formatToDatatable } from "@/common/formaterToDatatable";
import { defineComponent, computed, reactive, ref, onMounted } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { getModule } from "vuex-module-decorators";
import TableBillCreatedModule from "@/store/modules/bill/modules/table-bill-created";
import InputText from "@/components/forms/InputText.vue"
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import { Form } from 'vee-validate';
import { useRoute } from 'vue-router'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SendToSaleModal from "@/views/sales/modals/SendToSaleModal.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2";
import SwalMessageService from "@/core/services/SwalMessageService";
import Select from "@/components/forms/Select.vue";
import {toggleCollapse} from "@/core/helpers/dom";

export default defineComponent({
    components:{
        Select,
        ModuleMaster
        , Datatable
        , Datarow
        //, FilterBillCreated
        , InputText
        , DatePickerRange
        , Form
    },
    setup(){
        const moduleTable = getModule(TableBillCreatedModule)
        moduleTable.SEARCH_TABLE_DATA();
        const messageService = new SwalMessageService();
        const route = useRoute();
        const form = ref();
        const opciones = ref<any>([
            { id: "Pendientes" },
            { id: "Enviadas" },
            { id: "Todos" }
        ]);

        const handleCrudAction = async (id: string) => {
            Swal.fire({
                title: '¿Deseas enviar la factura por correo?',
                text: "Se procederá a enviar el correo con la factura adjunta.",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#000000',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Enviando factura...',
                        text: 'Por favor, espera mientras se procesa tu solicitud.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                    });

                    Swal.showLoading();

                    try {
                        let url = `Invoice/SendEmail/` + id;
                        await ApiService.query(url);
                        Swal.close();
                        messageService.success(`Se envio correctamente la factura`);

                        uppdateTable();
                    } catch (error) {
                        Swal.close();
                        messageService.error(error.response.data);
                    }
                }
            });
        }

        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "N° Factura", Type: "text", VisibleInGrid: true },
            { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "date", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true},
            { PropName: "isSend", HeadLabel: "Enviado", Type: "text", VisibleInGrid: true, Badge: (obj) => obj.isSend === "Pendiente" ? "D" : "S" },
            { PropName: "paymentType", HeadLabel: "Tipo", Type: "text", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
        ]);

        const labels = reactive({
            placeHolderToFieldSearch: "Bucar por N° Factura"
        })
        const handleChangePage = (page: number) => {
            //change page
            console.log(page);
        }

        const sendAll = async () => {
            const mappedData = renderRows.value.map(d => ({ id: d.ID, name: d.originalProps.customer }));

            const ids = mappedData.map(d => d.id);

            await sendAllBills(ids, mappedData);
        };

        const sendAllBills = async (ids: string[], clients: { id: string, name: string }[]) => {
            Swal.fire({
                title: 'Enviando facturas...',
                html: `
                    <div id="progress-container" style="text-align: left; overflow-x: auto; max-width: 100%; padding: 5px">
                        ${clients.map(client => `
                            <div id="progress-${client.id}" style="display: flex; gap: 10px; align-items: center; margin-bottom: 10px;">
                                <span style="flex: 1;">${client.name}</span>
                                <span id="status-${client.id}" style="flex: 0;">
                                    <i class="bi-arrow-counterclockwise spinner" style="font-size: 20px; color: #007bff;"></i>
                                </span>
                            </div>
                        `).join('')}
                    </div>
                `,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                width: '40%',
                customClass: {
                    popup: 'custom-popup'
                },
                padding: '5px'
            });

            const updateStatus = (id: string, status: 'success' | 'error') => {
                const statusElement = document.getElementById(`status-${id}`);
                if (statusElement) {
                    statusElement.innerHTML = status === 'success'
                        ? `<span style="color: green;">✔</span>`
                        : `<span style="color: red;">✘</span>`;
                }
            };

            for (const id of ids) {
                const client = clients.find(c => c.id === id);
                if (!client) continue;

                try {
                    const url = `Invoice/SendEmail/${id}`;
                    await ApiService.query(url);
                    updateStatus(id, 'success');
                } catch (error) {
                    updateStatus(id, 'error');
                }
            }

            setTimeout(() => {
                Swal.fire({
                    title: 'Proceso terminado',
                    text: 'Se han procesado todas las facturas.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                });

                uppdateTable();
            }, 3000);
        };

        const searchBills = (filters: Record<string, any>) => {
            switch (filters.sendBill) {
                case 'Pendientes':
                    filters = { ...filters, isSend: "Pendiente" };
                    break;
                case 'Enviadas':
                    filters = { ...filters, isSend: "Enviado" };
                    break;
                case 'Todos':
                    filters = { ...filters, isSend: null };
                    break;
                default:
            }

            moduleTable.UPDATE_FILTERS(filters);

            toggleCollapse("#contain-advance-search");
        }

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        // variables del store
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.records, configTable));
        const loading = computed(() => moduleTable.loading);

        onMounted(() => {
            setCurrentPageBreadcrumbs("Envio de facturas", ["Módulos", "Facturación"])

            uppdateTable();
        })

        const uppdateTable = () => {
            let request = {};
            const { saleFolio, numBill, dateStart, dateEnd, clientId, rfc, isSend, type} = route.query;

            if(saleFolio && saleFolio.length > 0){
                request = { ...request, saleFolio }
                form.value.setFieldValue("saleFolio", saleFolio);
            }if(numBill && numBill.length > 0){
                request = { ...request, folio: numBill }
                form.value.setFieldValue("folio", numBill);
            } if (dateStart && dateStart.length > 0) {
                request = { ...request, dateStart }
                form.value.setFieldValue("dateStart", dateStart);
            } if (dateEnd && dateEnd.length > 0) {
                request = { ...request, dateEnd }
                form.value.setFieldValue("dateEnd", dateEnd);
            } if (clientId && clientId.length > 0) {
                request = { ...request, clientId }
                form.value.setFieldValue("clientId", clientId);
            } if (rfc && rfc.length > 0) {
                request = { ...request, rfc }
                form.value.setFieldValue("rfc", rfc);
            }

            request = { ...request, type: null }
            request = { ...request, isSend: "Pendiente" }

            if(Object.keys(request).length > 0){
                moduleTable.UPDATE_FILTERS(request);
            }
        }

        return {
            SendToSaleModal,
            handleCrudAction,
            totalRecords
            , sizePage
            , renderRows
            , headers
            , labels
            , loading
            , handleChangePage
            , searchBills
            , sendAll
            , form
            , opciones
        }
    }
})
